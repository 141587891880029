import { Col, List, Row } from 'antd';
import { parsePhoneNumber } from 'libphonenumber-js';
import { first, get } from 'lodash';
import React from 'react';

import Text from '../components/Text';
import { prependPlusSignIfNoneFound } from './common';
import {
  selectStoreCountryByCode,
  selectStoreCurrentAuthUser,
  selectStoreCurrentCompany,
} from './storeSelectors';

const formatNumerIntl = (number, fractionDigits = [2, 3], additionalConfig = {}) => {
  const n = parseFloat(number);
  if (isNaN(n)) return '';
  try {
    return new Intl.NumberFormat(additionalConfig?.locale || 'en-US', {
      minimumFractionDigits: Array.isArray(fractionDigits) ? fractionDigits[0] : fractionDigits,
      maximumFractionDigits: Array.isArray(fractionDigits) ? fractionDigits[1] : fractionDigits,
      ...additionalConfig,
    })
      .format(n)
      .replace(/[.,]00$/, '')
      .replace(/\s+/g, ' ');
  } catch (error) {
    return n.toLocaleString().replace(/[.,]00$/, '');
  }
};

/**
 * Shorthand function to provide a formatted number based on the browsers locale
 *
 * @param {string | number} number Integer or Float number
 * @param {number | [min?: number, max?: number]=} fractionDigits Maximum amount of fraction digits. Default: 2
 * @param {object} additionalConfig Additional options for NumberFormat
 * @return {string} Formatted number
 */
export const formatNumberToLocale = (number, fractionDigits = [2, 3], additionalConfig = {}) => {
  return formatNumerIntl(number, fractionDigits, {
    ...additionalConfig,
    locale: 'en-US',
  });
};

/**
 * @param {string | number} number Integer or Float number
 * @param {string} currency Currency code
 * @param {number | [min?: number, max?: number]=} fractionDigits Maximum amount of fraction digits. Default: 2
 * @param {"symbol" | "code"} currencyDisplay Currency formatting. symbol -> $20 || code -> USD 20
 * @return {string} Formatted currency
 */
export const formatNumberWithCurrency = (
  number,
  currency,
  fractionDigits = [2, 3],
  currencyDisplay = 'symbol',
) => {
  return formatNumberToLocale(number, fractionDigits, {
    style: 'currency',
    currencyDisplay: ['CAD'].includes(currency) ? 'code' : currencyDisplay,
    currency: currency || 'USD',
  });
};

/**
 * @param {string | number} number Integer or Float number
 * @param {number | [min?: number, max?: number]=} fractionDigits Maximum amount of fraction digits. Default: 2
 * @param {"symbol" | "code"} currencyDisplay Currency formatting. symbol -> $20 || code -> USD 20
 * @return {string} Formatted currency
 */
export const formatCurrencyToRateLocale = (
  number,
  fractionDigits = [2, 3],
  currencyDisplay = 'symbol',
) => {
  const currentUser = selectStoreCurrentAuthUser();
  const userRateCountry = get(currentUser, 'profile.group.productId.country', 'US');
  const rateCountry = selectStoreCountryByCode(userRateCountry);
  const currency = rateCountry?.currencyCode || 'USD';

  return formatNumberToLocale(number, fractionDigits, {
    style: 'currency',
    currencyDisplay: ['CAD'].includes(currency) ? 'code' : currencyDisplay,
    currency: currency || 'USD',
  });
};

/**
 * @param {string | number} number Integer or Float number
 * @param {number | [min?: number, max?: number]=} fractionDigits Maximum amount of fraction digits. Default: 2
 * @param {"symbol" | "code"} currencyDisplay Currency formatting. symbol -> $20 || code -> USD 20
 * @return {string} Formatted currency
 */
export const formatCurrencyToCompanyLocale = (
  number,
  fractionDigits = [2, 3],
  currencyDisplay = 'symbol',
) => {
  const currentCompany = selectStoreCurrentCompany();
  const companyCountryCode = get(currentCompany, 'address.country', 'US');
  const companyCountry = selectStoreCountryByCode(companyCountryCode);
  const currency = currentCompany?.currencyUnit || companyCountry?.currencyCode || 'USD';

  return formatNumberToLocale(number, fractionDigits, {
    style: 'currency',
    currencyDisplay: ['CAD'].includes(currency) ? 'code' : currencyDisplay,
    currency: currency || 'USD',
  });
};

/**
 * Shorthand function to provide a formatted distance based on the country
 *
 * @param {string | number} number Integer or Float number
 * @param {number | [min?: number, max?: number]=} fractionDigits Maximum amount of fraction digits. Default: 2
 * @param {boolean=} useLongUnit Use long distance unit name
 * @return {string} Formatted distance
 */
export const formatDistanceToLocale = (number, fractionDigits, useLongUnit = false) => {
  const currentCompany = selectStoreCurrentCompany();
  const country = selectStoreCountryByCode(currentCompany?.address?.country);

  const n = parseFloat(number);

  const distance = formatNumberToLocale(n, fractionDigits);

  if (country) {
    return `${distance} ${useLongUnit ? country.distanceLong : country.distanceShort}`;
  } else {
    return `${distance} ${useLongUnit ? 'miles' : 'mi'}`;
  }
};

/**
 * @param {string | number} number Integer or Float number
 * @param {string | number} distanceUnit Integer or Float number
 * @param {number | [min?: number, max?: number]=} fractionDigits Maximum amount of fraction digits. Default: 2
 * @return {string} Formatted number
 */
export const formatNumberWithDistanceUnit = (number, distanceUnit, fractionDigits = [2, 3]) => {
  const formattedAmount = formatNumerIntl(number, fractionDigits, { locale: 'en-US' });
  return [formattedAmount, distanceUnit].join(' ');
};

/**
 * @param {string | number} number Integer or Float number
 * @param {number | [min?: number, max?: number]=} fractionDigits Maximum amount of fraction digits. Default: 2
 * @param {boolean} useLongUnit
 * @return {string} Formatted number
 */
export const formatDistanceToRateLocale = (number, fractionDigits, useLongUnit = false) => {
  const currentUser = selectStoreCurrentAuthUser();
  const userRateCountry = get(currentUser, 'profile.group.productId.country', 'US');
  const rateCountry = selectStoreCountryByCode(userRateCountry);

  const n = parseFloat(number);

  const distance = formatNumberToLocale(n, fractionDigits);

  if (rateCountry) {
    return `${distance} ${useLongUnit ? rateCountry.distanceLong : rateCountry.distanceShort}`;
  } else {
    return `${distance} ${useLongUnit ? 'miles' : 'mi'}`;
  }
};

/**
 * @param {string | number} number Integer or Float number
 * @param {string | number} distanceUnit Integer or Float number
 * @param {number | [min?: number, max?: number]=} fractionDigits Maximum amount of fraction digits. Default: 2
 * @return {string} Formatted number
 */
export const formatDistanceToCompanyLocale = (number, fractionDigits, useLongUnit = false) => {
  const currentCompany = selectStoreCurrentCompany();
  const companyCountryCode = get(currentCompany, 'address.country', 'US');
  const companyCountry = selectStoreCountryByCode(companyCountryCode);

  const n = parseFloat(number);

  const distance = formatNumberToLocale(n, fractionDigits);

  if (companyCountry) {
    return `${distance} ${
      useLongUnit ? companyCountry.distanceLong : companyCountry.distanceShort
    }`;
  } else {
    return `${distance} ${useLongUnit ? 'miles' : 'mi'}`;
  }
};

/**
 *
 * @param {number} number
 * @param {"meter" | "mile" | "kilometer"} originUnit
 * @param {"meter" | "mile" | "kilometer"} targetUnit
 * @param {number} fractionDigits
 * @returns {number}
 */
export const convertDistance = (
  number,
  originUnit = 'miles',
  targetUnit = 'mile',
  fractionDigits = 2,
) => {
  const parsedNumber = parseFloat(number);
  if (parsedNumber === 0) return 0;

  if (isNaN(parsedNumber)) return number;
  if (originUnit === targetUnit) return parsedNumber;

  if (originUnit === 'mile') {
    switch (targetUnit) {
      case 'meter':
        return parseFloat((parsedNumber * 1609.34).toFixed(fractionDigits));
      case 'kilometer':
        return parseFloat((parsedNumber * 1.60934).toFixed(fractionDigits));
      default:
        break;
    }
  }

  if (originUnit === 'meter') {
    switch (targetUnit) {
      case 'mile':
        return parseFloat((parsedNumber / 1609.34).toFixed(fractionDigits));
      case 'kilometer':
        return parseFloat((parsedNumber / 1000).toFixed(fractionDigits));
      default:
        break;
    }
  }

  if (originUnit === 'kilometer') {
    switch (targetUnit) {
      case 'meter':
        return parseFloat((parsedNumber * 1000).toFixed(fractionDigits));
      case 'mile':
        return parseFloat((parsedNumber / 1.60934).toFixed(fractionDigits));
      default:
        break;
    }
  }

  return parsedNumber;
};

/**
 * Formats a given number between 1 and 31 to its ordinal value.
 * To be used on dates (days)
 * @param {number} number Number to format
 */
export const formatOrdinalDay = number => {
  switch (number) {
    case 1:
    case 21:
      return number + 'st';
    case 2:
    case 22:
      return number + 'nd';
    case 3:
    case 23:
      return number + 'rd';
    default:
      return number + 'th';
  }
};

export const formatInternationalPhoneNumber = number => {
  return parsePhoneNumber(prependPlusSignIfNoneFound(number)).formatInternational();
};

export const getMinutesAsMilliseconds = minutes => {
  return 1000 * 60 * minutes;
};

export const parseTotalAmountsArray = (amounts = []) => {
  return {
    total: amounts.reduce((total, amountData) => total + amountData.totalAmount, 0),
    currency: get(first(amounts), 'currency') || 'USD',
    amounts: (
      <div>
        <Row gutter={[8, 8]}>
          {amounts.length === 0 ? (
            <Col xs={24}>{formatNumberToLocale(0)}</Col>
          ) : (
            <Col xs={24}>
              <List
                size="small"
                rowKey={amountData => amountData.currency}
                dataSource={amounts}
                renderItem={amountData => {
                  return (
                    <List.Item style={{ padding: 5 }}>
                      <Text size="sm">
                        {formatNumberWithCurrency(
                          amountData.totalAmount,
                          amountData.currency,
                          undefined,
                          'code',
                        )}
                      </Text>
                    </List.Item>
                  );
                }}
              />
            </Col>
          )}
        </Row>
      </div>
    ),
  };
};

export const convertLongDistanceUnitStringToShort = distanceUnitString => {
  switch (distanceUnitString) {
    case 'mile':
      return 'mi';
    case 'meter':
      return 'm';
    case 'kilometer':
      return 'km';
    default:
      return distanceUnitString;
  }
};
