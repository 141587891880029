import { Table } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { STATUS_LIST } from '../../../enum';
import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { formatNumberToLocale, formatNumberWithCurrency } from '../../../utils/numbers';
import { selectStoreCompanySettings } from '../../../utils/storeSelectors';
import { CustomTable } from '../..';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';
import AmountTextReverseColors from '../../Text/AmountTextReverseColors';
import MonthlyTaxReportReceiptsDetails from './MonthlyTaxReportReceiptsDetails';

const TaxReportMonthlyTable = props => {
  const { t, dataSource, currency, ...rest } = props;

  const [expandedRowsKeys, setExpandedRowsKeys] = useState([]);
  const companySettings = useSelector(selectStoreCompanySettings);

  const toggleExpandedRow = row => {
    const rowKey = get(row, 'startDate');
    if (rowKey && !expandedRowsKeys.includes(rowKey)) {
      setExpandedRowsKeys([rowKey]);
    } else {
      setExpandedRowsKeys([]);
    }
  };

  const COLUMNS = [
    {
      key: 'monthYear',
      title: t('month,Year'),
      dataIndex: ['startDate'],
      render: (startDate, data) =>
        startDate ? getMomentDateWithoutTimezone(startDate).format('MMM, YYYY') : '-',
    },
    {
      key: 'complianceStatus',
      title: t('complianceStatus'),
      dataIndex: 'complianceStatus',
      render: complianceStatus => <StatusTag status={complianceStatus} />,
    },
    {
      align: 'right',
      key: 'reimbursedAmount',
      title: t('reimbursedAmount'),
      dataIndex: 'reimbursedAmount',
      render: reimbursedAmount => formatNumberWithCurrency(reimbursedAmount, currency),
    },
    {
      align: 'right',
      key: 'milesDriven',
      title: t('milesDriven'),
      dataIndex: 'totalDistance',
      render: milesDriven => formatNumberToLocale(milesDriven),
    },
    {
      align: 'right',
      key: 'irsRate',
      title: t('irsRate'),
      dataIndex: 'irsRate',
      render: irsRate => formatNumberWithCurrency(irsRate, currency),
    },
    {
      align: 'right',
      key: 'irsTotal',
      title: t('irsTotal'),
      dataIndex: 'irsTotal',
      render: irsTotal => formatNumberWithCurrency(irsTotal, currency),
    },
    {
      align: 'right',
      key: 'favrIrsDifference',
      title: t('favr/irsDifference'),
      dataIndex: 'favrIrsDifference',
      render: favrIrsDifference =>
        typeof favrIrsDifference === 'number'
          ? formatNumberWithCurrency(favrIrsDifference, currency)
          : '-',
    },
    {
      align: 'right',
      key: 'taxLiability',
      title: t('taxLiability'),
      dataIndex: 'taxLiability',
      render: taxLiability => (
        <AmountTextReverseColors
          textAlign="right"
          amount={formatNumberWithCurrency(taxLiability, currency)}
        />
      ),
    },
  ];

  const expandableDataSource = useMemo(
    () =>
      dataSource.map(record => ({
        ...record,
        expandable: !!get(record, 'tripReceipts', [])?.length && !!get(record, 'startDate'),
      })),
    [dataSource],
  );

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      rowKey={row => get(row, 'startDate', +new Date())}
      showSearchInput={false}
      dataSource={expandableDataSource}
      scroll={{ x: 1100 }}
      pagination={{
        defaultPageSize: 25,
        showSizeChanger: false,
        hideOnSinglePage: true,
      }}
      withExpandableRows
      expandedRowKeys={expandedRowsKeys}
      onExpand={(expanded, record) => toggleExpandedRow(record)}
      expandedRowRender={data => {
        return (
          <>
            <Text variant="h4">
              {t(companySettings?.hideReceipts ? 'Reimbursements' : 'Receipts')}
            </Text>
            <MonthlyTaxReportReceiptsDetails
              t={t}
              dataSource={data.tripReceipts}
              receiptsHidden={!!companySettings?.hideReceipts}
              hiddenColumns={companySettings?.hideReceipts ? ['reimbursementID'] : []}
            />
          </>
        );
      }}
      summary={
        !!dataSource.length
          ? pageData => {
              const { liabilityTotal } = pageData.reduce(
                (totals, current) => {
                  if (current.complianceStatus === STATUS_LIST().Status.INVALID) {
                    totals.liabilityTotal = totals.liabilityTotal + current.taxLiability;
                  }
                  return totals;
                },
                { liabilityTotal: 0 },
              );

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text variant="b">{t('Total')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}></Table.Summary.Cell>
                  <Table.Summary.Cell index={3}></Table.Summary.Cell>
                  <Table.Summary.Cell index={4}></Table.Summary.Cell>
                  <Table.Summary.Cell index={5}></Table.Summary.Cell>
                  <Table.Summary.Cell index={6}></Table.Summary.Cell>
                  <Table.Summary.Cell index={7} align="right">
                    <AmountTextReverseColors
                      variant="b"
                      amount={formatNumberWithCurrency(liabilityTotal, currency)}
                    />
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}></Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }
          : undefined
      }
    />
  );
};

export default TaxReportMonthlyTable;
