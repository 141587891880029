const images = {
  KLIKS_LOGO_SIDEBAR: '/images/logo/logo-sidebar-v2.svg',
  KLIKS_LOGO_SIDEBAR_SMALL: '/images/logo/logo-sidebar-small-v2.svg',
  KLIKS_LOGO_SIDEBAR_SMALL_DARK: '/images/logo/logo-sidebar-small-v2_dark.svg',
  DOCS_ICON: '/images/icons/docs.svg',
  CHEVRON_DOWN_ICON: '/images/icons/chevron-down.svg',
  GREEN_CHEVRON_DOWN_ICON: '/images/icons/green-chevron-down.svg',
  CLOSE_ICON: '/images/icons/close.svg',
  HOME_ICON: '/images/icons/house-chimney-2.svg',
  MULTIPLE_USERS_ICON: '/images/icons/multiple-users-1.svg',
  MONEY_BAG_ICON: '/images/icons/money-bags.svg',
  MONEY_BAG_DARK_ICON: '/images/icons/money-bags--dark.svg',
  POLICY_MENU_ICON: '/images/img-menu-policy.png',
  CAR_RETRO_ICON: '/images/icons/car-retro-2.svg',
  CAR_RETRO_DARK_ICON: '/images/icons/car-retro-2-black.svg',
  SAFETY_MENU_ICON: '/images/img-menu-safety.png',
  FLEET_MENU_ICON: '/images/img-menu-fleet.png',
  CARBON_MENU_ICON: '/images/img-menu-carbon.png',
  ANALYTICS_PIE_ICON: '/images/icons/analytics-pie-1.svg',
  SETTINGS_ICON: '/images/icons/setting.svg',
  RECEIPT_ICON: '/images/icons/receipt.svg',
  RECEIPT_DARK_ICON: '/images/icons/receipt-dark.svg',
  CHECK_MARK_ICON: '/images/icons/checkmark.svg',
  CIRCLE_REMOVE_ICON: '/images/icons/remove-circle.svg',
  CIRCLE_CHECK_MARK_ICON: '/images/icons/check-circle-1.svg',
  ALERT_WARNING_TRIANGLE_ICON: '/images/icons/alert-triangle.svg',
  VERTICAL_MENU_DOTS_ICON: '/images/icons/navigation-menu-vertical.svg',
  VERTICAL_MENU_DOTS_GREEN_ICON: '/images/icons/navigation-menu-vertical-green.svg',
  HAMBURGER_MENU_ICON: '/images/icons/hamburguer-navigation-menu.svg',
  RED_MARK_MAP_ICON: '/images/red-marker.svg',
  GREEN_MARK_MAP_ICON: '/images/green-marker.svg',
  MOBILE_TRACK_ICON: '/images/icons/phone-action-location.svg',
  GPS_TRACK_ICON: '/images/icons/OBD-Device.svg',
  MANUAL_TRACK_ICON: '/images/icons/manual-input.svg',
  CALENDAR_ICON: '/images/icons/calendar.svg',
  CALENDAR_GREEN_ICON: '/images/icons/calendar-green.svg',
  ARROW_POINT_UP: '/images/icons/trend-up.svg',
  ARROW_POINT_DOWN: '/images/icons/trend-down.svg',
  BELL_ICON: '/images/icons/alarm-bell.svg',
  KEY_ICON: '/images/icons/login-key-1.svg',
  GREEN_PLUS_ICON: '/images/icons/Plus.svg',
  WHITE_PLUS_ICON: '/images/icons/Plus-White.svg',
  TRASH_ICON: '/images/icons/bin-1.svg',
  SEARCH_ICON: '/images/icons/search.svg',
  SETTINGS_STROKE_ICON: '/images/icons/settings-stroke.svg',
  DOWNLOAD_CIRCLE_ICON: '/images/icons/download-circle.svg',
  DOWNLOAD_BOX_ICON: '/images/icons/download-thick-bottom.svg',
  UPLOAD_BOX_ICON: '/images/icons/upload-thick-bottom.svg',
  EXPAND_WINDOW_ICON: '/images/icons/expand-window.svg',
  LONG_ARROW_RIGHT_ICON: '/images/icons/long-arrow-right.svg',
  MAP_PIN_ICON: '/images/icons/maps-pin.svg',
  MOVE_BACK_ARROW: '/images/icons/move-back.svg',
  EMAIL_ICON: '/images/icons/envelope.svg',
  LIST_TO_DO_ICON: '/images/icons/list-to-do.svg',
  LIST_TO_DO_WHITE_ICON: '/images/icons/list-to-do-white.svg',
  TEXT_FILE_DOWNLOAD_ICON: '/images/icons/common-file-download.svg',
  TEXT_FILE_REMOVE_ICON: '/images/icons/common-file-text-subtract.svg',
  TRIP_PINS_ICON: '/images/icons/trip-pins.svg',
  RECEIPT_DOLLAR_ICON: '/images/icons/receipt-dollar.svg',
  CALENDAR_CASH_ICON: '/images/icons/calendar-cash-1.svg',
  USER_SINGLE_ICON: '/images/icons/single-neutral.svg',
  USER_SINGLE_SHIELD_ICON: '/images/icons/neutral-shield-users.svg',
  CSV_FILE_ICON: '/images/icons/file-csv.svg',
  ARROW_CIRCLE_UP: '/images/icons/arrow-circle-up.svg',
  ARROW_CIRCLE_DOWN: '/images/icons/arrow-circle-down.svg',
  PLAN_IMAGE: '/images/img-plan-basic.png',
  REPORT_GRAPH_ICON: '/images/icons/data-file-graph.svg',
  DARK_REPORT_GRAPH_ICON: '/images/icons/data-file-graph-black.svg',
  GREEN_REPORT_GRAPH_ICON: '/images/icons/data-file-graph-green.svg',
  BUILDING_ICON: '/images/icons/building.svg',
  INFORMATION_CIRCLE: '/images/icons/information-circle.svg',
  IRS_LOGO: '/images/logo/IRS_logo.svg',
  TIME_CLOCK_CIRCLE_GRAY: '/images/icons/time-clock-circle.svg',
  INACTIVE_DRIVER_GREEN: '/images/icons/inactive-driver-2.svg',
  NEEDS_DRIVER_RED: '/images/icons/needs-a-driver-3-red.svg',
  NEEDS_DRIVER: '/images/icons/needs-a-driver-3.svg',
  ACTIVE_DRIVER_GREEN: '/images/icons/active-driver-green.svg',
  NOTES_PAPER_TEXT: '/images/icons/notes-paper-text.svg',
  REFRESH_ARROW_ICON: '/images/icons/refresh-arrow.svg',
  NAVIGATION_RIGHT_ARROW_ICON: '/images/icons/navigation-right.svg',
  NAVIGATION_RIGHT_WHITE_ARROW_ICON: '/images/icons/navigation-white-right.svg',
  GRAY_PIN_ICON: '/images/icons/pin.svg',
  SEARCH_CIRCLE_ICON: '/images/icons/search-circle.svg',

  CAR_CLOUD_ICON: '/images/car_clouds.png',
  CAR_DARK_FILL_ICON: '/images/icons/car-dark-fill.svg',
  CAR_SHIELD_DARK_FILL_ICON: '/images/icons/car-actions-shield-1.svg',
  TAGS_FAVORITE_DARK_FILL_ICON: '/images/icons/tags-favorite-dark-fill.svg',
  MULTIPLE_USERS_DARK_FILL_ICON: '/images/icons/multiple-users-dark-fill.svg',
  EYE_DAR_FILL_ICON: '/images/icons/view-dark-fill-1.svg',
  GREEN_LOCK_ICON: '/images/icons/green-lock.svg',

  CALENDAR_CHECK_ICON: '/images/icons/calendar-check.svg',
  CALENDAR_WARNING_ICON: '/images/icons/calendar-warning.svg',
  CALENDAR_TIMER_ICON: '/images/icons/calendar-timer.svg',

  HOME_PIN_ICON: '/images/icons/home-pin.svg',
  PHONE_DOWNLOAD_ICON: '/images/icons/phone-download.svg',
  VACATION_BEACH_ICON: '/images/icons/vacation-beach.svg',
  BANK_ACCOUNT_ICON: '/images/icons/saving-bank.svg',

  DRAG_HANDLE_ICON: '/images/icons/drag-handle.svg',

  CAR_ICON: '/images/icons/car.svg',
  CAR_INSURANCE_ICON: '/images/icons/car-insurance.svg',
  CREDIT_CARD_ICON: '/images/icons/credit-card-check.svg',
  CREDIT_CARD_DARK_ICON: '/images/icons/credit-card.svg',
  MASTERCARD_ICON: '/images/icons/mastercard.svg',
  VISA_ICON: '/images/icons/visa.svg',
  DISCOVER_ICON: '/images/icons/discover.svg',

  BELL_ALERT_ICON: '/images/icons/bell-alert.svg',
  BELL_CHECK_ICON: '/images/icons/bell-check.svg',
  BELL_CLOCK_ICON: '/images/icons/bell-timer.svg',
  BARCODE_SEARCH_ICON: '/images/icons/barcode-search.svg',
  PENCIL_WRITE_FORM_ICON: '/images/icons/pencil-write.svg',

  OOC_RED_ICON: '/images/icons/ooc-red-icon.svg',

  // Car Logos
  ACURA_LOGO: '/images/logo/cars/acura.svg',
  AUDI_LOGO: '/images/logo/cars/audi.svg',
  BMW_LOGO: '/images/logo/cars/bmw.svg',
  CADILLAC_LOGO: '/images/logo/cars/cadillac.svg',
  CHEVY_LOGO: '/images/logo/cars/chevy.svg',
  CHRYSLER_LOGO: '/images/logo/cars/chrysler.svg',
  DODGE_LOGO: '/images/logo/cars/dodge.svg',
  FORD_LOGO: '/images/logo/cars/ford.svg',

  VIN_FIND_IMAGE: '/images/VinFind.png',

  APP_STORE_BADGE_IMAGE: '/images/logo/play-store-badge.svg',
  PLAY_STORE_BADGE_IMAGE: '/images/logo/google-play-badge.svg',

  INSURANCE_FORM_SAMPLE_1: '/images/samples/geico-sample-insurance-form.png',
  INSURANCE_FORM_SAMPLE_2: '/images/samples/usaa-sample-insurance-form .png',

  EDIT_PENCIL_UP: '/images/edit-pencil-up.png',
};

export default images;
