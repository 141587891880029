import { QuestionCircleOutlined } from '@ant-design/icons';
import { Collapse, Descriptions, Space } from 'antd';
import React, { useMemo } from 'react';

import Text from '../../../components/Text';
import { IMAGES } from '../../../enum';
import { formatNumberToLocale } from '../../../utils/numbers';
import classNames from './style.module.scss';

const InsuranceMinimumCollapsibleSection = ({ t, insuranceCoverages }) => {
  const coverageAmounts = useMemo(() => {
    if (!insuranceCoverages) return [];

    return Object.keys(insuranceCoverages)
      .filter(key => typeof insuranceCoverages[key] === 'number' && insuranceCoverages[key] > 0)
      .map(key => ({
        label: key,
        value: formatNumberToLocale(insuranceCoverages[key]),
      }));
  }, [insuranceCoverages]);

  if (!coverageAmounts.length) return null;

  return (
    <Collapse
      className={classNames.collapsibleInsuranceMinimums}
      expandIcon={({ isActive }) => (
        <img
          src={IMAGES.GREEN_CHEVRON_DOWN_ICON}
          alt="expand icon"
          style={{ transform: `translateY(-3px) rotate(${isActive ? 0 : 180}deg)` }}
        />
      )}
    >
      <Collapse.Panel
        key="open"
        header={
          <Space align="center" direction="horizontal" wrap={false}>
            <QuestionCircleOutlined />
            <Text variant="b">{t('minimumInsuranceCoverageAmounts')}</Text>
          </Space>
        }
      >
        <Descriptions bordered size="small" column={1} style={{ maxWidth: 500 }}>
          {coverageAmounts.map(item => (
            <Descriptions.Item key={item.label} label={t(item.label)}>
              <Text textAlign="right" style={{ minWidth: 'max-content' }}>
                {item.value}
              </Text>
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Collapse.Panel>
    </Collapse>
  );
};

export default InsuranceMinimumCollapsibleSection;
