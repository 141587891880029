import { Col, Row } from 'antd';
import React, { useCallback, useState } from 'react';

import Box from '../../components/Box';
import FormItem from '../../components/Form/FormItem';
import StatusTag from '../../components/Tag/StatusTag';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import TextInput from '../../components/TextInput';
import { STATUS_LIST } from '../../enum';
import { momentTimezone } from '../../utils/common';
import { ERROR_MESSAGE } from '../../utils/constants';
import { getMomentDateWithoutTimezone } from '../../utils/datetime';
import { convertLongDistanceUnitStringToShort } from '../../utils/numbers';

const MileageItemCard = props => {
  const {
    t,
    item,
    disabled,
    onMileageCreate,
    onMileageUpdate,
    onMileageDelete,
    distanceUnit,
  } = props;

  const [inputErrorMessage, setInputErrorMessage] = useState();

  const momentDate = getMomentDateWithoutTimezone(item.date, 'YYYY-MM-DD');
  const momentDay = momentDate.day();
  const isSunday = momentDay === 0;
  const isSaturday = momentDay === 6;
  const isToday = momentDate.isSame(momentTimezone(), 'date');
  const isAfterToday = momentDate.isAfter(momentTimezone(), 'date');

  const isTripOnApprovalStatus =
    item.trip && item.trip?.journeyStatus !== STATUS_LIST().Status.PENDING;

  const handleMileageChange = useCallback(
    mileage => {
      if (mileage === '' || mileage >= 0) {
        setInputErrorMessage();
        const values = {
          tripId: item.trip?._id,
          journeyDate: item.date,
          journeyDistance: parseFloat(mileage),
        };

        if (values.journeyDistance !== item.trip?.journeyDistance) {
          if (values.tripId && (mileage === '' || values.journeyDistance === 0)) {
            onMileageDelete(values);
          } else if (values.tripId) {
            onMileageUpdate(values);
          } else {
            onMileageCreate(values);
          }
        }
      } else {
        setInputErrorMessage(ERROR_MESSAGE().MIN_NUMBER(0));
      }
    },
    [item, onMileageDelete, onMileageCreate, onMileageUpdate, setInputErrorMessage],
  );

  return (
    <Col xs={24} lg={12} xl={6} key={item.date}>
      <Box padding="16px">
        <Row align="middle" gutter={8}>
          <Col>
            <Text
              size="large"
              variant="b"
              renderAs="p"
              color={isSaturday || isSunday ? 'red' : 'secondary'}
            >
              {t(momentDate.format('dddd'))}
            </Text>
          </Col>

          {isToday && (
            <Col>
              <Text color="red" variant="b" renderAs="p">
                ({t('today')})
              </Text>
            </Col>
          )}

          {item.trip && item.trip?.journeyStatus !== STATUS_LIST().Status.PENDING && (
            <Col>
              <StatusTag size="small" status={item.trip?.journeyStatus} />
            </Col>
          )}
        </Row>

        <Row align="middle" gutter={[0, 8]}>
          <Col flex="100%">
            <FadedText variant="b" renderAs="p" size="sm">
              {momentDate.format('MMMM DD, YYYY')}
            </FadedText>
          </Col>

          <Col flex="100%">
            <FormItem
              hasFeedback={!!inputErrorMessage}
              validateStatus={!!inputErrorMessage ? 'error' : ''}
              errorMessage={inputErrorMessage}
            >
              <TextInput
                type="number"
                min={0}
                maxLength={4}
                defaultValue={item.trip?.journeyDistance}
                onChange={handleMileageChange}
                suffix={
                  convertLongDistanceUnitStringToShort(item.trip?.distanceUnit) || distanceUnit
                }
                disabled={disabled || isTripOnApprovalStatus || isAfterToday}
              />
            </FormItem>
          </Col>
        </Row>
      </Box>
    </Col>
  );
};

export default MileageItemCard;
