import React from 'react';

import FormItem from '../../../../components/Form/FormItem';
import PaymentPeriodSelect from '../../../../components/PaymentPeriodSelect';
import SectionStepWrapper from '../../../../components/SectionStepWrapper';

const SelectPaymentPeriodSection = ({
  t,
  step,
  currentStep,
  loading,
  value,
  paymentScheduleId,
  paymentScheduleType,
  onValuesChange,
}) => {
  return (
    <SectionStepWrapper
      step={step}
      title={t('selectPaymentPeriod')}
      showContent={currentStep >= step}
      isCompleted={currentStep > step}
    >
      <FormItem label={t('paymentPeriod')} name="paymentPeriod">
        <PaymentPeriodSelect
          t={t}
          disabled={loading}
          value={value}
          paymentScheduleId={paymentScheduleId}
          paymentScheduleType={paymentScheduleType}
          onChange={paymentPeriod => {
            onValuesChange(currentValues => ({
              ...currentValues,
              paymentScheduleType,
              paymentPeriod,
            }));
          }}
        />
      </FormItem>
    </SectionStepWrapper>
  );
};

export default SelectPaymentPeriodSection;
