import { Descriptions, Space } from 'antd';
import React, { useMemo } from 'react';

import { formatNumberWithDistanceUnit } from '../../../utils/numbers';
import HelpIcon from '../../HelpIcon';

const JourneyDistanceDisplay = ({
  t,
  distance,
  originalJourneyDistance,
  additionalJourneyDistance,
}) => {
  const hasAdditionalDistance = useMemo(
    () => additionalJourneyDistance && parseFloat(additionalJourneyDistance) > 0,
    [additionalJourneyDistance],
  );

  const distanceUnit = useMemo(() => {
    return `${distance || ''}`.split(' ')[1];
  }, [distance]);

  if (hasAdditionalDistance) {
    return (
      <Space wrap={false} align="center" size="small" style={{ justifyContent: 'end' }}>
        <p style={{ width: 'max-content', height: 22 }}>{distance}</p>

        <HelpIcon
          noPadding
          hint={
            <Descriptions bordered size="small" column={1} contentStyle={{ textAlign: 'right' }}>
              <Descriptions.Item label={t('originalDistance')}>
                {typeof originalJourneyDistance === 'string'
                  ? originalJourneyDistance
                  : formatNumberWithDistanceUnit(originalJourneyDistance, distanceUnit)}
              </Descriptions.Item>
              <Descriptions.Item label={t('additionalDistance')}>
                {typeof additionalJourneyDistance === 'string'
                  ? additionalJourneyDistance
                  : formatNumberWithDistanceUnit(additionalJourneyDistance, distanceUnit)}
              </Descriptions.Item>
              <Descriptions.Item label={t('totalDistance')}>{distance}</Descriptions.Item>
            </Descriptions>
          }
        />
      </Space>
    );
  }

  return distance;
};

export default JourneyDistanceDisplay;
