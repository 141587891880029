import { Col, Empty, Form, Row, Space } from 'antd';
import React, { useMemo } from 'react';
import { useMutation } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { TRIP_API } from '../../api/trips';
import { selectStoreCurrentAuthUser, useStoreSelector } from '../../utils/storeSelectors';
import FormItem from '../Form/FormItem';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import Text from '../Text';
import TextInput from '../TextInput';
import Toast from '../Toast';

const TripNoteSection = props => {
  const { t, trip, notes, onTripNoteUpdate } = props;

  const authUser = useStoreSelector(selectStoreCurrentAuthUser);
  const [formInstace] = Form.useForm();

  const updateTripDistanceMutation = useMutation(
    ({ notes }) => TRIP_API.updateTrip(trip._id, { notes: notes === '' ? null : notes }),
    {
      onSuccess: async (res, { notes }) => {
        Toast({
          type: 'open',
          message: t('updateTripNoteSuccess'),
        });

        if (typeof onTripNoteUpdate === 'function') {
          onTripNoteUpdate(trip._id, { notes });
        }
      },
      onError: error =>
        handleApiErrors(error.response, () => {
          Toast({
            type: 'open',
            message: t('updateTripNoteError'),
          });
        }),
    },
  );

  const CAN_UPDATE_NOTE = useMemo(() => {
    return trip.driverID === authUser.profile._id;
  }, [trip.driverID, authUser.profile._id]);

  if (!CAN_UPDATE_NOTE && !notes) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <Text size="sm" textAlign="center">
            {t('noDataToDisplay')}
          </Text>
        }
      />
    );
  }

  return (
    <section>
      <Form
        form={formInstace}
        onFinish={updateTripDistanceMutation.mutateAsync}
        defaultValue={{ notes }}
      >
        <Space direction="vertical" size="small">
          <FormItem name="notes" className="no-margin-bottom">
            <TextInput
              textArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              defaultValue={notes}
              disabled={updateTripDistanceMutation.isLoading || !CAN_UPDATE_NOTE}
            />
          </FormItem>

          {CAN_UPDATE_NOTE && (
            <Row gutter={[8, 8]} align="middle" justify="end">
              <Col>
                <SubmitCancelButtonGroup
                  isForm
                  hideCancel
                  size="xs"
                  loading={updateTripDistanceMutation.isLoading}
                  submitText={t('updateNotes')}
                />
              </Col>
            </Row>
          )}
        </Space>
      </Form>
    </section>
  );
};

export default TripNoteSection;
