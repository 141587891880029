import { List, Table } from 'antd';
import { get } from 'lodash';
import React from 'react';

import { getMomentDateWithoutTimezone } from '../../../utils/datetime';
import { formatNumberToLocale, formatNumberWithCurrency } from '../../../utils/numbers';
import { CustomTable } from '../..';
import ExpandableScrollableListWrapper from '../../ExpandableScrollableListWrapper';
import Text from '../../Text';
import AmountTextReverseColors from '../../Text/AmountTextReverseColors';

const TaxReportAnnualTable = props => {
  const { t, dataSource, currency, ...rest } = props;

  const COLUMNS = [
    {
      key: 'periods',
      title: t('paymentPeriod'),
      dataIndex: 'periods',
      render: periods => {
        return (
          <ExpandableScrollableListWrapper t={t} align="">
            <List
              size="small"
              bordered={false}
              dataSource={periods}
              renderItem={period => {
                const periodString = [
                  getMomentDateWithoutTimezone(period.startDate).format('MM-DD-YYYY'),
                  getMomentDateWithoutTimezone(period.endDate).format('MM-DD-YYYY'),
                ].join(' / ');

                return <List.Item key={periodString}>{periodString}</List.Item>;
              }}
            />
          </ExpandableScrollableListWrapper>
        );
      },
    },
    {
      align: 'right',
      key: 'irsRate',
      title: t('totalMiles/IrsRate'),
      dataIndex: 'irsRate',
      render: (irsRate, data) =>
        [
          formatNumberToLocale(data.totalDistance),
          formatNumberWithCurrency(irsRate, currency),
        ].join(' / '),
    },
    {
      align: 'right',
      key: 'reimbursedAmount',
      title: t('favrTotal'),
      dataIndex: 'reimbursedAmount',
      render: reimbursedAmount => formatNumberWithCurrency(reimbursedAmount, currency),
    },
    {
      align: 'right',
      key: 'irsTotal',
      title: t('irsRateTotal'),
      dataIndex: 'irsTotal',
      render: irsTotal => formatNumberWithCurrency(irsTotal, currency),
    },
    {
      align: 'right',
      key: 'taxLiability',
      title: t('taxLiability'),
      dataIndex: 'taxLiability',
      render: taxLiability => (
        <AmountTextReverseColors
          textAlign="right"
          amount={formatNumberWithCurrency(taxLiability, currency)}
        />
      ),
    },
  ];

  return (
    <CustomTable
      {...rest}
      columns={COLUMNS}
      rowKey={row => +new Date(get(row, 'period.startDate'))}
      showSearchInput={false}
      dataSource={dataSource}
      scroll={{ x: 1100 }}
      pagination={{ defaultPageSize: 25, showSizeChanger: false, hideOnSinglePage: true }}
      summary={
        !!dataSource.length
          ? pageData => {
              const { favrTotal, irsTotal, liabilityTotal } = pageData.reduce(
                (totals, current) => {
                  totals.favrTotal = totals.favrTotal + current.reimbursedAmount;
                  totals.irsTotal = totals.irsTotal + current.irsTotal;
                  totals.liabilityTotal = totals.liabilityTotal + current.taxLiability;
                  return totals;
                },
                { favrTotal: 0, irsTotal: 0, liabilityTotal: 0 },
              );

              return (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Text variant="b">{t('Total')}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="right">
                    <Text variant="b">{formatNumberWithCurrency(favrTotal, currency)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="right">
                    <Text variant="b">{formatNumberWithCurrency(irsTotal, currency)}</Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="right">
                    <AmountTextReverseColors
                      variant="b"
                      amount={formatNumberWithCurrency(liabilityTotal, currency)}
                    />
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }
          : undefined
      }
    />
  );
};

export default TaxReportAnnualTable;
