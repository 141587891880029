import getSymbolFromCurrency from 'currency-symbol-map';

import { STATUS_LIST } from '../enum';
import { getNumberRage, momentCompanyTimezone } from './common';
import { selectStoreCurrentCompany } from './storeSelectors';

export const isActiveCompany = company => {
  return [STATUS_LIST().Status.ACTIVE, STATUS_LIST().Status.ACTIVE_TRIAL].includes(company.status);
};

export const isCurrentCompanySubActive = store => {
  const currentCompany = selectStoreCurrentCompany(store);
  if (!currentCompany?.status) return true;

  return isActiveCompany(currentCompany);
};

/**
 * @returns {{ currencyUnit: string, currencySymbol: string }}
 */
export const getCompanyCurrency = () => {
  const currentCompany = selectStoreCurrentCompany();
  const currencyUnit = currentCompany?.currencyUnit || 'USD';
  return {
    currencyUnit,
    currencySymbol: getSymbolFromCurrency(currencyUnit),
  };
};

/**
 * Generates an array of year options since the company's creation date.
 *
 * @param {function} t - Translation function for localizing year labels.
 * @param {Date|string} createdDate - The date when the company was created.
 * @returns {Array<{ label: string, value: number }>} - An array of objects containing year options with labels and values.
 */
export const getCompanyYearsOptionsSinceCreated = (t, createdDate) => {
  const currentYear = new Date().getFullYear();
  const MINIMUM_YEAR = momentCompanyTimezone(createdDate).year();
  const YEAR_RANGE = getNumberRage(MINIMUM_YEAR, currentYear).reverse();

  const options = YEAR_RANGE.map(year => ({
    label: year === currentYear ? t('thisYear', { year: currentYear }) : year,
    value: year,
  }));

  return options;
};
