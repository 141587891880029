import { find } from 'lodash';
import React, { useEffect } from 'react';

import usePaymentSchedules from '../../hooks/usePaymentSchedules';
import Select from '../Select';

const PaymentScheduleSelect = props => {
  const { t, disabled, onChange, value, placeholder, defaultActiveFirstOption, ...rest } = props;

  const {
    isFetchingPaymentSchedules,
    paymentScheduleOptions,
    paymentSchedules,
  } = usePaymentSchedules();

  useEffect(() => {
    if (
      defaultActiveFirstOption &&
      !value &&
      !!paymentScheduleOptions.length &&
      typeof onChange === 'function'
    ) {
      onChange(
        paymentScheduleOptions[0].value,
        paymentScheduleOptions[0],
        find(paymentSchedules, { _id: paymentScheduleOptions[0].value }),
      );
    }
  }, [defaultActiveFirstOption, onChange, paymentScheduleOptions, paymentSchedules, value]);

  return (
    <Select
      {...rest}
      fullWidth
      showSearch
      allowClear={false}
      placeholder={
        isFetchingPaymentSchedules ? t('searching...') : placeholder || t('paymentSchedule')
      }
      value={value && !paymentScheduleOptions.length ? undefined : value}
      disabled={disabled || isFetchingPaymentSchedules}
      loading={isFetchingPaymentSchedules}
      options={paymentScheduleOptions}
      optionFilterProp="children"
      onChange={(value, option) => {
        if (typeof onChange === 'function') {
          onChange(value, option, find(paymentSchedules, { _id: value }));
        }
      }}
    />
  );
};

export default PaymentScheduleSelect;
