import { get } from 'lodash';
import React from 'react';

import ApprovedReimbursementsSection from '../FormSteps/ApprovedReimbursementsSection';
import PayrollFileModificationSummary from '../FormSteps/PayrollFileModificationSummary';
import SelectPaymentPeriodSection from '../FormSteps/SelectPaymentPeriodSection';
import SelectPaymentScheduleSection from '../FormSteps/SelectPaymentScheduleSection';

const CreatePayrollFileStepGroup = ({ t, currentStep, loading, values, onValuesChange }) => {
  if (get(values, 'actionType') !== 'create') return null;

  return (
    <>
      <SelectPaymentScheduleSection
        t={t}
        step={2}
        currentStep={currentStep}
        loading={loading}
        onValuesChange={onValuesChange}
        value={get(values, 'paymentScheduleId')}
      />

      <SelectPaymentPeriodSection
        t={t}
        step={3}
        currentStep={currentStep}
        loading={loading}
        onValuesChange={onValuesChange}
        value={get(values, 'paymentPeriod')}
        paymentScheduleId={get(values, 'paymentScheduleId')}
        paymentScheduleType={get(values, 'paymentScheduleType')}
      />

      <ApprovedReimbursementsSection
        t={t}
        step={4}
        currentStep={currentStep}
        loading={loading}
        paymentScheduleId={get(values, 'paymentScheduleId')}
        tripReceiptsIds={get(values, 'tripReceiptsIds', [])}
        onValuesChange={onValuesChange}
      />

      <PayrollFileModificationSummary t={t} step={5} currentStep={currentStep} values={values} />
    </>
  );
};

export default CreatePayrollFileStepGroup;
