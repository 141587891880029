import { Col, Row, Spin } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { PAYMENT_SCHEDULES_API } from '../../api/paymentSchedule';
import { checkIfElementIsScrolledToBottom } from '../../utils/common';
import { formatPaymentPeriodLabel } from '../../utils/trip-receipts';
import Select from '../Select';

const PaymentPeriodSelect = props => {
  const {
    t,
    showAllOption,
    onChange,
    notFoundContent,
    value,
    disabled,
    paymentScheduleId,
    paymentScheduleType,
    ...rest
  } = props;

  const [initialCount, setInitialCount] = useState();
  const [isLookingForMoreOptions, setIsLookingForMoreOptions] = useState(true);

  const paymentPeriodsQuery = useInfiniteQuery({
    enabled: !!paymentScheduleId,
    placeholderData: {
      documents: [],
      totalCount: 0,
    },
    queryKey: ['fetchPaymentPeriodsByScheduleId', paymentScheduleId],
    queryFn: () => PAYMENT_SCHEDULES_API.fetchPaymentPeriodsByScheduleId(paymentScheduleId),
    getNextPageParam: (lastPage, pages) => {
      const { totalCount = 0 } = lastPage || {};
      const totalPages = Math.ceil(totalCount / 24);
      if (pages.length < totalPages) return pages.length + 1;
    },
    onSuccess: ({ pages }) => {
      if (
        Array.isArray(pages) &&
        typeof pages[0]?.totalCount !== 'undefined' &&
        typeof initialCount === 'undefined'
      ) {
        setInitialCount(pages[0]?.totalCount);
      }
    },
  });

  const flatPages = useMemo(() => {
    let optionsArray = [];

    if (Array.isArray(paymentPeriodsQuery?.data?.pages)) {
      paymentPeriodsQuery.data.pages.forEach(({ documents }) => {
        optionsArray = optionsArray.concat(documents);
      });
    }

    return optionsArray;
  }, [paymentPeriodsQuery]);

  const options = useMemo(() => {
    let optionsArray = flatPages.map(item => {
      item.scheduleType = item.scheduleType || paymentScheduleType;
      return {
        label: formatPaymentPeriodLabel(item, 'paymentPeriodStart', 'paymentPeriodEnd'),
        value: JSON.stringify({
          paymentPeriodStart: item.paymentPeriodStart,
          paymentPeriodEnd: item.paymentPeriodEnd,
        }),
      };
    });

    if (showAllOption) {
      optionsArray.unshift({ value: null, label: t('All') });
    }

    return optionsArray;
  }, [t, paymentScheduleType, flatPages, showAllOption]);

  const handleChange = useCallback(
    (value, option) => {
      if (typeof onChange === 'function') {
        const fullSelectedItem = flatPages.find(item => item._id === value);
        onChange(value, fullSelectedItem);
      }
    },
    [flatPages, onChange],
  );

  const handleOptionsScroll = useCallback(
    e => {
      const bottom = checkIfElementIsScrolledToBottom(e.target);
      if (bottom && !paymentPeriodsQuery.isFetchingNextPage && paymentPeriodsQuery.hasNextPage) {
        paymentPeriodsQuery.fetchNextPage();
      }
    },
    [paymentPeriodsQuery],
  );

  const optionsWithoutNullValues = useMemo(() => {
    return options.filter(opt => !!opt.value);
  }, [options]);

  useEffect(() => {
    if (value) {
      if (!!options?.length) {
        const valueInOptions = !!options.find(option => option.value === value);
        if (!valueInOptions && !paymentPeriodsQuery.isFetching && paymentPeriodsQuery.hasNextPage) {
          setIsLookingForMoreOptions(true);
          paymentPeriodsQuery.fetchNextPage();
        } else if (
          !paymentPeriodsQuery.isFetching &&
          (!paymentPeriodsQuery.hasNextPage || valueInOptions)
        ) {
          setIsLookingForMoreOptions(false);
        }
      }
    } else {
      setIsLookingForMoreOptions(false);
    }
  }, [value, options, paymentPeriodsQuery]);

  return (
    <Select
      {...rest}
      resetOnNoMatch={false}
      onChange={handleChange}
      value={
        (value && !optionsWithoutNullValues.length) || isLookingForMoreOptions ? undefined : value
      }
      fullWidth
      showSearch={false}
      allowClear={false}
      disabled={disabled || isLookingForMoreOptions}
      loading={paymentPeriodsQuery.isFetchingNextPage}
      options={options}
      placeholder={isLookingForMoreOptions ? t('searching...') : t('paymentPeriod')}
      onPopupScroll={handleOptionsScroll}
      notFoundContent={
        paymentPeriodsQuery.isFetching ? (
          <Row justify="center" align="middle">
            <Col>
              <Spin size="default" />
            </Col>
          </Row>
        ) : initialCount === 0 ? (
          /* message to display only if the initial search has not results,
           hence any other search term queries will be return the same */
          notFoundContent
        ) : undefined
      }
    />
  );
};

export default PaymentPeriodSelect;
