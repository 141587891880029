import { Col, Row, Space, Timeline } from 'antd';
import { get, isString } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { IMAGES, STATUS_LIST } from '../../../enum';
import { splitAddressString } from '../../../utils/address';
import { momentTimezone } from '../../../utils/common';
import { getCenterCoordinates, getStaticMapImageURL } from '../../../utils/map';
import { getActiveRoutes } from '../../../views/trips/trips-page-helpers';
import { canAddAdditionalDistance, canDeleteTrip } from '../../../views/trips/trips-permissions';
import BottomBorderTextRows from '../../BottomBorderTextRows';
import Button from '../../Button';
import MapLoader from '../../MapLoader';
import Popover from '../../Popover';
import SpaceSpinner from '../../SpaceSpinner';
import Text from '../../Text';
import FadedText from '../../Text/FadedText';
import LinkText from '../../Text/LinkText';
import Tooltip from '../../Tooltip';
import TripNotesAndCommentsSection from '../../TripNotesAndCommentsSection';
import TripsAddAdditionalDistanceSection from '../../TripsAddAdditionalDistanceSection';
import TripCalculationTable from '../TripCalculationTable';
import classNames from './style.module.scss';

/**
 * Expanded row for the Trips Table
 */
const ExpandedTripsSubRow = props => {
  const {
    t,
    isFetchigDetails,
    onViewFullScreenClick,
    trip,
    onDeleteTrip,
    onViewMoreComments,
    authUserID,
    onUpdateAndSubmit,
    onTripNoteUpdate,
    ...rest
  } = props;

  const queryClient = useQueryClient();
  const tripDetails = trip?.journeyGeoJson
    ? trip
    : queryClient.getQueryData(['fetchTripDetails', trip._id]);

  const [activeRoutes, setActiveRoutes] = useState([]);
  const [mapCenter, setMapCenter] = useState();
  const [isDeletingTrip, setIsDeletingTrip] = useState(false);

  const renderCheckmark = value => {
    return (
      <img
        width="15px"
        alt={value === 'No' ? 'Remove' : 'Check'}
        src={value === 'No' ? IMAGES.CIRCLE_REMOVE_ICON : IMAGES.CIRCLE_CHECK_MARK_ICON}
      />
    );
  };

  const map = useMemo(() => {
    if (isFetchigDetails || !tripDetails) return null;

    let routes, center;
    let mapHasError;

    try {
      routes = getActiveRoutes(tripDetails);
      mapHasError = !routes.length;

      if (!mapHasError) {
        const centerConfig = getCenterCoordinates(tripDetails.journeyGeoJson.geometry.coordinates);
        center = centerConfig.center;

        setMapCenter(center);
        setActiveRoutes(routes);
      }
    } catch (error) {
      mapHasError = true;
    }

    return mapHasError ? (
      <Row
        justify="center"
        align="middle"
        style={{ width: '100%', height: 304, background: '#F6F7F8' }}
      >
        <Col flex="179px">
          <MapLoader text={t('errorLoadingMap')} />
        </Col>
      </Row>
    ) : (
      <img
        alt="Trip map"
        src={getStaticMapImageURL(routes, tripDetails.journeyGeoJson, 563, 304, true)}
        width={563}
        height={304}
        title={'Click to view details'}
        onClick={() => {
          if (typeof onViewFullScreenClick === 'function') {
            onViewFullScreenClick(trip, routes, center);
          }
        }}
      />
    );
  }, [t, trip, tripDetails, onViewFullScreenClick, isFetchigDetails]);

  const CAN_DELETE_TRIP = useMemo(() => canDeleteTrip(trip), [trip]);

  const handleTripDelete = async tripId => {
    setIsDeletingTrip(true);
    await onDeleteTrip([tripId]);
    setIsDeletingTrip(false);
  };

  if (!activeRoutes.length || !mapCenter || isFetchigDetails || !tripDetails) {
    return <SpaceSpinner />;
  }

  return (
    <div {...rest} className={classNames.expandedTripRow}>
      <Col flex="45%">
        <div className={classNames.map}>{map}</div>

        <Row align="middle" style={{ marginRight: 15 }}>
          <Col flex={1}>
            {CAN_DELETE_TRIP && typeof onDeleteTrip === 'function' && (
              <Tooltip
                title={t('onlyPendingAndDeniedTripsCanBeDeleted')}
                {...([
                  STATUS_LIST().Status.PENDING,
                  STATUS_LIST().Status.DENIED,
                  STATUS_LIST().Status.MORE_INFO,
                ].includes(trip.status)
                  ? { visible: false }
                  : {})}
              >
                <Button
                  size="sm"
                  disabled={
                    isDeletingTrip ||
                    ![
                      STATUS_LIST().Status.PENDING,
                      STATUS_LIST().Status.DENIED,
                      STATUS_LIST().Status.MORE_INFO,
                    ].includes(trip.status)
                  }
                  loading={isDeletingTrip}
                  onClick={() => handleTripDelete(trip._id)}
                >
                  {t('deleteTrip')}
                </Button>
              </Tooltip>
            )}
          </Col>

          <Col>
            <LinkText
              onClick={() => {
                if (typeof onViewFullScreenClick === 'function') {
                  onViewFullScreenClick(trip, activeRoutes, mapCenter);
                }
              }}
            >
              {t('viewDetailedRoute')}
            </LinkText>
          </Col>
        </Row>
      </Col>

      <Row gutter={[16, 16]} style={{ flex: 1 }}>
        <Col xs={24} className={classNames.timelineWrapper}>
          <Timeline mode="left">
            <Timeline.Item
              color="red"
              label={
                <div>
                  <Text textAlign="right">
                    {momentTimezone(trip.journeyStartTs).format('MMM DD, YYYY')}
                  </Text>
                  <FadedText textAlign="right" size="sm">
                    {momentTimezone(trip.journeyStartTs).format('hh:mm a z')}
                  </FadedText>
                </div>
              }
            >
              {isString(trip.from_loc) ? (
                splitAddressString(trip.from_loc).map(string => <Text>{string}</Text>)
              ) : (
                <Text>-</Text>
              )}
            </Timeline.Item>
            <Timeline.Item
              color="green"
              label={
                <div>
                  <Text textAlign="right">
                    {momentTimezone(trip.journeyEndTs).format('MMM DD, YYYY')}
                  </Text>
                  <FadedText textAlign="right" size="sm">
                    {momentTimezone(trip.journeyEndTs).format('hh:mm a z')}
                  </FadedText>
                </div>
              }
            >
              {isString(trip.from_loc) ? (
                splitAddressString(trip.to_loc).map(string => <Text>{string}</Text>)
              ) : (
                <Text>-</Text>
              )}
            </Timeline.Item>
          </Timeline>
        </Col>

        <Col xs={24}>
          <Space size="large">
            <div>
              <FadedText>{t('totalDistance')}</FadedText>
              <Text variant="b" renderAs="p" textAlign="right">
                {get(trip, 'journeyDistance')}
              </Text>
            </div>

            {parseFloat(get(trip, 'additionalJourneyDistance') || '') > 0 && (
              <>
                <div>
                  <FadedText>{t('originalDistance')}</FadedText>
                  <Text variant="b" renderAs="p" textAlign="right">
                    {get(trip, 'originalJourneyDistance')}
                  </Text>
                </div>
                <div>
                  <FadedText>{t('additionalDistance')}</FadedText>
                  <Text variant="b" renderAs="p" textAlign="right">
                    {get(trip, 'additionalJourneyDistance')}
                  </Text>
                </div>
              </>
            )}
          </Space>
        </Col>

        <Col style={{ minWidth: 300, maxWidth: 500 }}>
          <Space direction="vertical" size="middle">
            {typeof onUpdateAndSubmit === 'function' && canAddAdditionalDistance(trip, authUserID) && (
              <div>
                <TripsAddAdditionalDistanceSection
                  t={t}
                  trip={trip}
                  onUpdateAndSubmit={onUpdateAndSubmit}
                />
              </div>
            )}

            <div>
              <TripNotesAndCommentsSection
                t={t}
                trip={trip}
                onViewMoreComments={onViewMoreComments}
                onTripNoteUpdate={onTripNoteUpdate}
              />
            </div>

            <BottomBorderTextRows
              rowContent={[
                {
                  label: t('optimalMilage'),
                  value: trip.optimalMileage,
                },
                {
                  label: t('GPS'),
                  value: renderCheckmark(trip.gps),
                },
                {
                  label: t('Company'),
                  value: renderCheckmark(trip.company_loc),
                },
                {
                  label: t('crmMatch'),
                  value: renderCheckmark(tripDetails.isCRMTrip ? 'Yes' : 'No'),
                },
                {
                  label: t('commute'),
                  value: renderCheckmark(trip.commute),
                },
              ]}
            />

            {((Array.isArray(trip.calculations) && !!trip.calculations.length) ||
              !!tripDetails?.pricing?.commute?.commuteDistance) && (
              <div>
                <Popover
                  trigger="click"
                  title={
                    <Text variant="p" size="m" textAlign="center">
                      <b>{t('tripAmountCalculation')}</b>
                    </Text>
                  }
                  content={
                    <div style={{ minWidth: 400 }}>
                      <div>
                        <TripCalculationTable
                          t={t}
                          dataSource={trip.calculations}
                          distanceUnit={trip.distanceUnit}
                          currency={trip?.currency}
                          commute={tripDetails?.pricing?.commute}
                        />
                      </div>
                    </div>
                  }
                >
                  <LinkText style={{ display: 'block' }}>
                    *{t('howWasTripCalculated')} {t('clickForDetails')}
                  </LinkText>
                </Popover>
              </div>
            )}
          </Space>
        </Col>
      </Row>
    </div>
  );
};

ExpandedTripsSubRow.propTypes = {
  activeRoutes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onViewFullScreenClick: PropTypes.func.isRequired,
  trip: PropTypes.shape({}).isRequired,
  onUpdateAndSubmit: PropTypes.func,
  mapCenter: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
};

ExpandedTripsSubRow.defaultProps = {
  activeRoutes: [],
};

export default ExpandedTripsSubRow;
