import { Col, Form, Row, Space } from 'antd';
import { get, isNull } from 'lodash';
import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { TRIP_API } from '../../api/trips';
import Button from '../Button';
import FormItem from '../Form/FormItem';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import NumericInput from '../TextInput/NumericInput';
import Toast from '../Toast';

const TripsAddAdditionalDistanceSection = props => {
  const { t, trip, onUpdateAndSubmit } = props;

  const queryClient = useQueryClient();
  const [formInstace] = Form.useForm();
  const currentAdditionalDistance = Form.useWatch('additionalJourneyDistance', formInstace);

  const updateTripDistanceMutation = useMutation(
    ({ additionalJourneyDistance }) => {
      const distance = parseFloat(additionalJourneyDistance);
      return TRIP_API.updateTrip(trip._id, {
        additionalJourneyDistance: isNaN(distance) ? 0 : distance,
      });
    },
    {
      onSuccess: async (response, { submit }) => {
        if (submit) {
          onUpdateAndSubmit(trip._id);
        } else {
          Toast({
            type: 'open',
            message: t('updateTripDistanceSuccess'),
          });
        }

        queryClient.refetchQueries({
          inactive: false,
          exact: false,
          queryKey: ['companyTrips'],
        });
      },
      onError: error =>
        handleApiErrors(error.response, () => {
          Toast({
            type: 'open',
            message: t('updateTripDistanceError'),
          });
        }),
    },
  );

  const [initialDistanceValue, distanceUnit] = useMemo(() => {
    const journeyDistance = get(trip, 'journeyDistance', '');

    let additionalJourneyDistance = get(trip, 'additionalJourneyDistance', '');
    additionalJourneyDistance = !isNull(additionalJourneyDistance)
      ? parseFloat(additionalJourneyDistance)
      : '';

    return [
      additionalJourneyDistance === 0 ? '' : `${additionalJourneyDistance}`,
      journeyDistance.split(' ')[1],
    ];
  }, [trip]);

  return (
    <section>
      <Form
        key={initialDistanceValue}
        form={formInstace}
        disabled={updateTripDistanceMutation.isLoading}
        onFinish={updateTripDistanceMutation.mutateAsync}
        initialValues={{ additionalJourneyDistance: initialDistanceValue }}
      >
        <Space direction="vertical" size="small">
          <FormItem
            name="additionalJourneyDistance"
            label={t('additionalDistance')}
            className="no-margin-bottom"
          >
            <NumericInput suffix={distanceUnit} />
          </FormItem>

          <Row gutter={[8, 8]} align="middle" justify="end">
            <Col>
              <Button
                size="xs"
                onClick={() => {
                  formInstace.validateFields().then(({ additionalJourneyDistance }) => {
                    if (initialDistanceValue !== additionalJourneyDistance) {
                      updateTripDistanceMutation.mutateAsync({
                        submit: true,
                        additionalJourneyDistance,
                      });
                    } else {
                      onUpdateAndSubmit(trip);
                    }
                  });
                }}
              >
                {t('submitForPayment')}
              </Button>
            </Col>

            <Col>
              <SubmitCancelButtonGroup
                isForm
                hideCancel
                size="xs"
                submitText={t('saveDistance')}
                disabled={currentAdditionalDistance === initialDistanceValue}
              />
            </Col>
          </Row>
        </Space>
      </Form>
    </section>
  );
};

export default TripsAddAdditionalDistanceSection;
