import { Col, Row, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

import TabsContainer from '../TabsContainer';
import LinkText from '../Text/LinkText';
import TripNoteSection from '../TripNoteSection';
import TripsCommentsTimeline from '../TripsCommentsTimeline';

const COMMENTS_TAB = 'comments';
const NOTE_TAB = 'note';

const TripNotesAndCommentsSection = props => {
  const { t, trip, onViewMoreComments, onTripNoteUpdate } = props;

  const [activeTab, setActiveTab] = useState(NOTE_TAB);

  const HAS_COMMENTS = !!trip.latestComment;

  useEffect(() => {
    if (HAS_COMMENTS) {
      setActiveTab(COMMENTS_TAB);
    } else {
      setActiveTab(NOTE_TAB);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <TabsContainer activeKey={activeTab} onChange={setActiveTab}>
      {!!HAS_COMMENTS && (
        <Tabs.TabPane key={COMMENTS_TAB} tab={t('comments')}>
          {/* Comment */}
          <TripsCommentsTimeline
            t={t}
            comments={HAS_COMMENTS ? [trip.latestComment] : []}
            canAddComment={false}
          />

          {HAS_COMMENTS && typeof onViewMoreComments === 'function' && (
            <Row justify="center">
              <Col>
                <LinkText onClick={() => onViewMoreComments(trip._id)}>{t('viewMore')}</LinkText>
              </Col>
            </Row>
          )}
        </Tabs.TabPane>
      )}

      <Tabs.TabPane key={NOTE_TAB} tab={t('notes')}>
        <TripNoteSection t={t} trip={trip} notes={trip.notes} onTripNoteUpdate={onTripNoteUpdate} />
      </Tabs.TabPane>
    </TabsContainer>
  );
};

export default TripNotesAndCommentsSection;
