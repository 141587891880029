import { Space } from 'antd';
import React, { useLayoutEffect, useRef, useState } from 'react';

import { IMAGES } from '../../enum';
import LinkText from '../Text/LinkText';

const ExpandableScrollableListWrapper = props => {
  const { t, children, itemsToShowInitialRender, align } = props;

  const wrapperRef = useRef();
  const initialItemsHeight = useRef();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const [totalItems, setTotalItems] = useState(0);

  useLayoutEffect(() => {
    if (wrapperRef.current) {
      let elementHeight = 0;
      const listItems = wrapperRef.current.querySelectorAll('.ant-list-item');
      const itemsToCount = Math.min(itemsToShowInitialRender, listItems.length);

      setTotalItems(listItems.length);

      for (let index = 0; index < itemsToCount; index++) {
        elementHeight += listItems[index].offsetHeight;
      }

      const initialHeight = Math.max(elementHeight, 0) + 'px';

      wrapperRef.current.style.height = initialHeight;
      initialItemsHeight.current = initialHeight;

      setShowExpandButton(listItems.length > itemsToShowInitialRender);
    }
  }, [itemsToShowInitialRender]);

  return (
    <Space direction="vertical" wrap={false} size="small" align={align}>
      <div
        ref={wrapperRef}
        style={{
          overflow: 'auto',
          height: isExpanded ? 'calc-size(auto, size)' : initialItemsHeight.current,
          transition: 'height 250ms ease',
        }}
      >
        {children}
      </div>
      {showExpandButton && (
        <Space
          direction="horizontal"
          wrap={false}
          size="small"
          align="center"
          style={{ cursor: 'pointer' }}
          onClick={() => setIsExpanded(currentState => !currentState)}
        >
          <img
            src={IMAGES.GREEN_CHEVRON_DOWN_ICON}
            alt="expand icon"
            style={{
              transition: 'transform 250ms ease',
              transform: `translateY(-3px) rotate(${isExpanded ? 0 : 180}deg)`,
            }}
          />
          <LinkText size="sm">
            {t(isExpanded ? 'viewLess' : 'viewAll')} {!isExpanded ? `(${totalItems})` : ''}
          </LinkText>
        </Space>
      )}
    </Space>
  );
};

ExpandableScrollableListWrapper.defaultProps = {
  showExpandButton: false,
  itemsToShowInitialRender: 3,
  align: 'end',
};

export default ExpandableScrollableListWrapper;
