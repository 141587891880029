import { Descriptions, Space } from 'antd';
import { get } from 'lodash';
import React, { useMemo } from 'react';

import SectionStepWrapper from '../../../../components/SectionStepWrapper';
import Text from '../../../../components/Text';
import { formatNumberWithCurrency } from '../../../../utils/numbers';
import { formatPaymentPeriodLabel } from '../../../../utils/trip-receipts';

const PayrollFileModificationSummary = ({ t, step, currentStep, values }) => {
  /**
   * This is the total number of unique user Ids present in all Trip Receipts
   * @type {number}
   */
  const totalOfUniqueUsersThatSubmittedReimbursements = useMemo(() => {
    return get(values, 'tripReceipts', []).reduce((acc, curr) => {
      return acc.add(get(curr, 'submittedBy._id'));
    }, new Set()).size;
  }, [values]);

  /**
   * This is the total amount of approved reimbursements per currency
   * @type {Map<string, number>}
   */
  const totalAmountsPerCurrency = useMemo(() => {
    return get(values, 'tripReceipts', []).reduce((acc, curr) => {
      const itemCurrency = get(curr, 'currency');
      const itemTotalAmount = get(curr, 'totalAmount');

      if (acc.has(itemCurrency)) {
        acc.set(itemCurrency, acc.get(itemCurrency) + itemTotalAmount);
      } else {
        acc.set(itemCurrency, itemTotalAmount);
      }
      return acc;
    }, new Map());
  }, [values]);

  return (
    <SectionStepWrapper
      step={5}
      showContent={currentStep === step}
      isCompleted={currentStep === step}
      title={t('summary')}
    >
      <Text>{t('selectedItemsWillBe')}:</Text>

      <ul>
        <li>
          <Text>{t('markedAsPaid')}</Text>
        </li>
        <li>
          <Text>{t('newPayrollFileGenerated')}</Text>
        </li>
        <li>
          <Text>{t('previousPayrollFileReplaced')}</Text>
        </li>
      </ul>

      <Descriptions size="small" column={1} layout="horizontal">
        {get(values, 'actionType') === 'create' && get(values, 'paymentPeriod') && (
          <Descriptions.Item label={<Text variant="b">{t('paymentPeriod')}</Text>}>
            {formatPaymentPeriodLabel(
              {
                scheduleType: get(values, 'paymentScheduleType'),
                ...JSON.parse(get(values, 'paymentPeriod', '')),
              },
              'paymentPeriodStart',
              'paymentPeriodEnd',
            )}
          </Descriptions.Item>
        )}

        <Descriptions.Item label={<Text variant="b">{t('totalUsersAffected')}</Text>}>
          {totalOfUniqueUsersThatSubmittedReimbursements}
        </Descriptions.Item>

        <Descriptions.Item
          label={<Text variant="b">{t('totalNumberReceiptsReimbursements')}</Text>}
        >
          {get(values, 'tripReceipts', []).length}
        </Descriptions.Item>

        <Descriptions.Item label={<Text variant="b">{t('totalAmount')}</Text>}>
          <Space direction="vertical" size="small" wrap={false}>
            {Array.from(totalAmountsPerCurrency.entries()).map(([currency, amount]) => (
              <Text key={currency}>{formatNumberWithCurrency(amount, currency)}</Text>
            ))}
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </SectionStepWrapper>
  );
};

export default PayrollFileModificationSummary;
