import { get } from 'lodash';
import React from 'react';

import FormItem from '../../../../components/Form/FormItem';
import PaymentScheduleSelect from '../../../../components/PaymentScheduleSelect';
import SectionStepWrapper from '../../../../components/SectionStepWrapper';

const SelectPaymentScheduleSection = ({ t, step, currentStep, loading, onValuesChange }) => {
  return (
    <SectionStepWrapper
      step={step}
      title={t('selectPaymentSchedule')}
      showContent={currentStep >= step}
      isCompleted={currentStep > step}
    >
      <FormItem label={t('paymentSchedule')} name="paymentScheduleId">
        <PaymentScheduleSelect
          t={t}
          disabled={loading}
          onChange={(value, option, schedule) => {
            onValuesChange(currentValues => ({
              ...currentValues,
              paymentScheduleId: value,
              paymentScheduleType: get(schedule, 'scheduleType'),
            }));
          }}
        />
      </FormItem>
    </SectionStepWrapper>
  );
};

export default SelectPaymentScheduleSection;
