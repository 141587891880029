import { isNull } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { INTERNAL_LINKS } from '../../../enum';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import { cleanQueryParams } from '../../../utils/queryParams';
import { scrollAppContainerToTop } from '../../../utils/window';
import { CustomTable } from '../..';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import AmountTextReverseColors from '../../Text/AmountTextReverseColors';
import LinkText from '../../Text/LinkText';

const CompanyTaxReportTable = props => {
  const { t, dataSource, taxYear, ...rest } = props;

  const COLUMNS = [
    {
      defaultSortOrder: 'ascend',
      key: ['firstName', 'lastName'].join(','),
      title: t('name'),
      sorter: true,
      render: ({ firstName, lastName, userId, profilePicture }) => (
        <ProfileNamePicture
          showStatus={false}
          user={{
            profilePicture,
            firstName,
            lastName,
            _id: userId,
          }}
        />
      ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('status'),
      sorter: true,
      render: status => <StatusTag status={status} />,
    },
    {
      key: 'groupName',
      title: t('Group'),
      dataIndex: 'groupName',
      sorter: true,
      render: (groupName, data) => (
        <Link to={`${INTERNAL_LINKS.GROUP}/${data.groupId}`}>
          <LinkText variant="b">{groupName}</LinkText>
        </Link>
      ),
    },
    {
      key: 'taxDue',
      title: t('taxDue'),
      dataIndex: 'taxDue',
      sorter: true,
      render: (taxDue, { userId, currency }) =>
        isNull(taxDue) ? (
          '-'
        ) : (
          <Link
            to={{
              pathname: INTERNAL_LINKS.USER_TAX_REPORT(userId),
              search: cleanQueryParams({ year: taxYear }),
            }}
            onClick={scrollAppContainerToTop}
          >
            <AmountTextReverseColors
              variant="b"
              amount={formatNumberWithCurrency(taxDue || 0, currency)}
            />
          </Link>
        ),
    },
    {
      align: 'center',
      key: 'complianceHistory',
      title: t('complianceHistory'),
      render: ({ userId }) => {
        return (
          <Link
            to={{
              pathname: INTERNAL_LINKS.USER_PROGRAM_COMPLIANCE,
              search: cleanQueryParams({ userId }),
            }}
          >
            <LinkText variant="b">{t('view')}</LinkText>
          </Link>
        );
      },
    },
  ];

  return (
    <CustomTable {...rest} showSearchInput={false} columns={COLUMNS} dataSource={dataSource} />
  );
};

CompanyTaxReportTable.defaultProps = {
  dataSource: [],
};

export default CompanyTaxReportTable;
