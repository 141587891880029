export const normalizeAddressSchema = schema => {
  if (!schema) return {};

  return {
    streetOne: schema.streetOne || schema.street1,
    streetTwo: schema.streetTwo || schema.street2,
    city: schema.city,
    state: schema.state,
    postalCode: schema.postalCode || schema.zipcode,
    country: schema.country,
  };
};

export const analyseAddressAutoCompleteString = addressString => {
  let [streetOne, city, stateInfo] = addressString.split(',').map(item => item.trim());
  let [state, country, postalCode] = (stateInfo || '')
    .split(/^(\w+) (\w+) (.+)/g)
    .filter(Boolean)
    .map(item => item.trim());

  switch (country) {
    case 'CAN':
    case 'Canada':
      country = 'CA';
      break;
    case 'USA':
    case 'United States':
      country = 'US';
      break;
    default:
      break;
  }

  return { streetOne, city, state, country, postalCode };
};

/**
 * Splits an address string into its components: the street address and the postal code with country.
 *
 * @param {string} addressString - The address string to be split.
 * @returns {[string, string]} An array containing the street address and the postal code with country.
 *
 * @example
 * splitAddressString('123 Main St, NY 12345-6789 US');
 * // returns ['123 Main St, NY', 'US, 12345-6789']
 *
 * @example
 * splitAddressString('123 Main St NY US 12345-6789');
 * // returns ['123 Main St NY', 'US, 12345-6789']
 *
 * @example
 * splitAddressString('456 Elm St, A1B 2C3');
 * // returns ['456 Elm St', 'CA, A1B 2C3']
 */
export const splitAddressString = addressString => {
  const regex = /(.*?)(\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d),?\s?(\w{2,3})$/;

  const match = addressString.match(regex) || [];
  let [, streetStateCountry, postalCode, , countryCode] = match;

  if (streetStateCountry && postalCode && countryCode) {
    return [streetStateCountry, `${countryCode}, ${postalCode}`];
  }

  const additionalRegex = /(.*?)(\w{2,3}),?\s?(\d{5}(-\d{4})?|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d)$/;
  [, streetStateCountry, countryCode, postalCode] = addressString.match(additionalRegex) || [];

  if (streetStateCountry && postalCode && countryCode) {
    return [streetStateCountry, `${countryCode}, ${postalCode}`]; // Return empty street and the matched country code with postal code
  }

  return [addressString, '']; // Fallback if no match is found
};
